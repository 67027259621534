<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Promotion V2">
            <!--
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Sales Office</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="territory"
                        :options="optionTerritory"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="code"
                        label="Name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <hr>
            -->
            <vs-tabs :color="colorx" ref="tabs">
                <vs-tab @click="changeTab(0)" label="Form">
                    <div class="con-tab-ejemplo">
                        <br>
                        <formPromotion :selectedPromotion="selectedPromotion" v-on:selectPromotion="updateSelectedPromotion" :MonitoringType="isApproval"></formPromotion>
                    </div>
                </vs-tab>
                <vs-tab @click="changeTab(1)" label="Tracker">
                    <div class="con-tab-ejemplo">
                        <br>
                        <monitoring v-on:selectPromotion="updateSelectedPromotion" v-on:redirectTab="redirectTab" @clickDetail="clickDetail"></monitoring>
                    </div>
                </vs-tab>
                <!-- <vs-tab @click="changeTab(2)" label="Matrix">
                    <div class="con-tab-ejemplo">
                        <br>
                        <matrix></matrix>
                    </div>
                </vs-tab>
                <vs-tab @click="changeTab(3)" label="Set">
                    <div class="con-tab-ejemplo">
                        <br>
                        <set></set>
                    </div>
                </vs-tab> -->
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>
import formPromotion from "./form/form.vue";
import monitoring from "./monitoring/index.vue";
import matrix from "./matrix/index.vue";
import set from "./set/index.vue";

export default {
    components: {
        formPromotion,
        monitoring,
        matrix,
        set,
    },
    mounted() {
        // this.getOptionTerritory();
        // console.log("mounted", this.selectedPromotion);
    },
    data: () => ({
        tabColor:['primary', 'rgb(62, 201, 214)', 'warning', 'rgb(33, 187, 55)'],
        tabIndex: 0,
        territory: {},
        optionTerritory: [{}],
        salesChannel: {},
        optionSalesChannel:[{}],
        customerCategory: {},
        isApproval:"",
        optionCustomerCategory:[{}],
        colorx: "success",
        selectedPromotion: {}
    }),
    watch: {
        // territory: function(val) {
        //     console.log(val.id);
        //     console.log(val.code);
        // }
    },
    methods: {
        changeTab(index) {
            // console.log("before", this.tabIndex);
            // console.log("after", index);
            this.selectedPromotion = null
            this.tabIndex = index;
            this.colorx = this.tabColor[index];
            this.isApproval ="";
        },
        getOptionTerritory() {
            this.$http.get("/api/v1/master/territory").then(resp => {
                this.optionTerritory = resp.data.records;
                this.territory = resp.data.records[0];
            });
        },
        updateSelectedPromotion(promotion) {
            this.selectedPromotion = promotion;
            
            this.tabIndex = 0;
            this.colorx = this.tabColor[0];
            // console.log("selected", this.selectedPromotion);
        },
        redirectTab(index) {
            this.$refs.tabs.activeChild(index);
        },

        clickDetail(promotion){
            this.selectedPromotion = promotion;
            this.isApproval = "approval" //biar formnya di disable
            console.log("check Detail",this.isApproval)
            this.tabIndex = 0;
            this.colorx = this.tabColor[0];
        },
    }
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>